var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// TODO: Use npm packages
import 'bootstrap';
import 'jquery-blockui';
import 'jquery-migrate';
import 'magnific-popup';
import './legacy';
addEventListener('DOMContentLoaded', () => __awaiter(void 0, void 0, void 0, function* () {
    yield import(/* webpackChunkName: "vendors-deferred" */ './deferredLegacy');
    if (document.querySelector('#html-editor')) {
        yield import('../RedactorPlugin');
        yield import('../HtmlEditor');
    }
}));
